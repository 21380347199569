<template>
    <div class="scwj ">
        <div class="wenjscbox">
            <el-upload class="upload-demo" :action="baseURL" multiple accept=".zip,.rar" ref="schuanwj"
                :on-change="handleSuccess" :on-remove="remove" :limit="1" :file-list="fileList" :auto-upload="false"
                :data="{
                }">
                <img class="scimg" :src="flag?OK:NO">
                
                <div class="el-upload__text" v-show="flag">点击上传口扫文件</div>
                <div class="el-upload__tip" slot="tip">文件限制：最大100M，支持rar.zip格式</div>
            </el-upload>
        </div>
    </div>
</template>
<script>
import OSS from 'ali-oss';
export default {
    
    data() {
        return {
            isOpen:true,
            fileName:'',
            token:{},
            // 是否是新建,true不是,false是
            isNew: true,
            fileList: [],
            // 是否上传成功
            flag: true,
            OK: require('@/assets/3新建病例/上传文件.png'),//已上传
            NO: require('@/assets/3新建病例/PC-新建病例上传文件.png'),//未上传
            type: '',
            scwjadd: 'Y',
            baseURL: '',// 上传文件路径默认地址
        };
    },
    methods: {
        saveScanLog(params) {
            this.$http({
                url: 'caseInfo/saveScanLog',
                method: 'POST',
                data: params

            }).then((data1) => {

            }).catch(res => {

            })
        },
        // ==
        // =======================\\
        schuawj() {
            this.$refs.schuanwj.submit();
        },
        // 判断文件格式
        ifType(file) {
            const fileExt = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
            const isJPG = fileExt == '.rar' || fileExt == '.zip'
            const isLt2M = file.raw.size / 1024 / 1024 < 100;
            if (!isJPG) {
                this.$message.error('上传文件只支持 rar/zip 格式!');
                return false
            }
            if (!isLt2M) {
                this.$message.error('上传文件最大100MB !');
                return false
            }
            return true;
        },
        // 改变事件
        handleSuccess(file, fileList) {
            console.log(fileList)
            if (!this.ifType(file)) {
                this.fileList = []
                return
            }
            this.fileList = fileList//需要上传的文件
            this.scwjadd = 'N';//是否新建
            this.flag = false;
            //if (!this.isNew) return;
            this.newLodImg();
        },
        remove() {
            if(this.isOpen){
                this.isOpen=false
            this.deleteimg();
            this.fileList = [];
            this.flag = true;
        }},
        saveScanLog(params){
            this.$http({
                url: 'caseInfo/saveScanLog',
                method: 'POST',
                data: params

            }).then((data1) => {

            }).catch(res=>{

            })
        },
      

        newLodImg() {
            this.loadingInstance = this.Loading.service({
        text: '正在上传'
      });
            var eightFormdata = new FormData();

            eightFormdata.append('caseId', this.$store.state.binglId)
            this.$http({
                url: '/caseInfo/getCaseInfoById',
                method: 'POST',
                data: eightFormdata

            }).then((data1) => {
                let res = JSON.parse(data1.data.data);
                console.log(res.accessKeySecret);

                this.caseNo = res.caseinfoId;
                this.token.accessKeySecret = res.accessKeySecret
                this.token.accessKeyId = res.accessKeyId
                this.token.securityToken = res.securityToken
                this.token.ossAddress = res.ossAddress
                this.token.bucketName = res.bucketName;
                console.log(this.token.ossAddress);
                /* if (data.code == 200) {
                 
                  console.log(data);
                 
                } */

                const data = {
                    // 上传文件
                    file: this.fileList[0].raw,
                    // 病例 id
                    caseId: this.$store.state.binglId,
                    // 病例阶段
                    stageName: this.$store.state.stageName,
                    // 病例次数
                    stageCount: this.$store.state.stageCount,
                    // 文件序号
                    fileNumber: 12,
                    //是否新增
                    add: 'Y'
                }
                var client = new OSS({
                    region: 'oss-' + this.token.ossAddress, // oss地址
                    accessKeyId: this.token.accessKeyId, // 通过阿里云控制台创建的AccessKey ID。
                    accessKeySecret: this.token.accessKeySecret, // 通过阿里云控制台创建的AccessKey Secret。
                    stsToken: this.token.securityToken,
                    bucket: this.token.bucketName, // 仓库名字
                    useFetch: true, // 支持上传大于100KB的文件
                    // secure:true // 返回的url为https
                });
                let stageName = this.$store.state.stageName;
                let stage = ''
                if (stageName == 1) {
                    stage = 'FirstDesign';
                } else if (stageName == 2) {
                    stage = 'MidTermRestart';
                } else if (stageName == 3) {
                    stage = 'FinalAdjustment';
                } else if (stageName == 4) {
                    stage = 'AfterProduction';
                }
                console.log(this.caseNo);
                var fileName = this.caseNo + '/' + stage + '/' + this.$store.state.stageCount + '/SCAN/' + new Date().getTime() + data.file.name.substr(data.file.name.indexOf('.'))
               this.fileName=fileName
               let uuid=new Date().getTime()
               this.saveScanLog({
                uuid,
                            outerno: this.caseNo,
                            file: this.fileName,
                            stage: this.$store.state.stageName == 1 ? 1 : 2, //1新建 2重启
                            type: 2
                        })
                client.multipartUpload(fileName, data.file).then(res => {

                    this.saveScanLog({
                        uuid,
                            outerno: this.caseNo,
                            file: fileName,
                            stage: this.$store.state.stageName == 1 ? 1 : 2, //1新建 2重启
                            type: 1
                        })

                   
                    let params = {
                        // 病例 id
                        caseId: this.$store.state.binglId + '',
                        // 病例阶段
                        stageName: this.$store.state.stageName,
                        // 病例次数
                        stageCount: this.$store.state.stageCount,
                        // 文件序号
                        fileNumber: '12',
                        //是否新增
                        add: 'Y',
                        ossfilePath: res.name,
                        fileName: data.file.name,

                    }
                    var eightFormdata = new FormData();
                    for (var k in params) {
                        eightFormdata.append(k, params[k]);
                    }
                    this.$http({
                        url: "/caseInfo/stepEightScan",
                        method: "POST",
                        data: eightFormdata,
                    }).then(({
                        data
                    }) => {
                        this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            this.loadingInstance.close();

                        });
                        this.$message({
                            message: "保存成功上传成功",
                            type: "success",
                            offset:700,
                            duration: 3000,
                            onClose: () => {
                                this.visible = false;
                                this.$emit("refreshDataList");
                            }
                        });

                     
                    }).catch(err => {
                        this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            this.loadingInstance.close();

                        });
                        this.$message({ message: '保存失败上传失败', type: 'error', duration: 1500 })
                        console.log(err)
                    })

                }).catch(err => {
                   
                    this.$message({ message: '上传oss失败', type: 'error', duration: 1500 })

                    console.log(err);
                })
            })
                .catch((err) => {
                    this.$message({ message: '获取oss信息失败', type: 'error', duration: 1500 })

                    console.log(err);
                });
        },
        deleteimg() {
            // 删除 图片 接口
            this.$http({
                url: "/caseInfo/delPhoto",
                method: "POST",
                data: {
                    // 病例 id
                    caseId: this.$store.state.binglId,
                    // 病例阶段
                    stageName: this.$store.state.stageName,
                    // 病例次数
                    stageCount: this.$store.state.stageCount,
                    // 文件序号
                    fileNumber: 12
                }
            }).then(({
                data
            }) => {
              this.isOpen=true
            }).catch(err => {
              this.isOpen=true

                console.log(err)
            })
        }
    }
}
</script>
<style scoped lang="less">
/deep/.upload-demo {
    .el-upload-list {
        position: absolute;
        left: 50%;
        top: 225px;
        transform: translateX(-50%);
        width: 200px;

        .el-upload-list__item-status-label,
        .el-icon-close {
            top: -238px;
            right: -202px;
            opacity: 0;
            display: block;
        }

        .el-upload-list__item-status-label {
            .el-icon-upload-success {
                width: 604px;
                height: 300px;
            }
        }

        .el-icon-close {
            width: 604px;
            height: 300px;
        }
    }
}

.scwj {
    height: 682px;
    position: relative;
}

/deep/.wenjscbox {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 300px;
    border: 2px solid #d9d9d9;
    border-radius: 6px;

    &:hover {
        border-color: #1175d2;
    }

    .el-upload__tip {
        text-align: center;
        font-size: 16px;
        color: #333333;
    }
}

/deep/ .el-upload-list__item {
    text-align: center;
}

/deep/ .el-upload-list__item-name {
    margin-right: 0;
}

/deep/.el-upload {
    position: relative;
    width: 600px;
    height: 300px;

    .el-upload-dragger {
        width: 100%;
        height: 100%;
        border: none;
    }

    .scimg {
        position: absolute;
        left: 51%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .el-upload__text {
        position: absolute;
        left: 50%;
        top: 70%;
        transform: translate(-50%, -50%);

        color: #f99400;
        font-size: 16px;

    }

}
</style>